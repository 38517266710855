<script lang="ts" context="module">
    export interface ModalWaitlistAppUserProps {
        checkboxPrivacyLabel: string;
        labelButtonSubmit: string;
        labelEmail: string;
        locale: SupportedLocale;
        submitError: string;
        title: string;
        visible: boolean;
    }
</script>

<script lang="ts">
    import ThreeQuarterSpinner from "@components/animations/ThreeQuarterSpinner.svelte";
    import Button from "@components/controls/Button.svelte";
    import Checkbox from "@components/controls/Checkbox.svelte";
    import ErrorMessage from "@components/controls/ErrorMessage.svelte";
    import HiddenInput from "@components/controls/HiddenInput.svelte";
    import TextInput from "@components/controls/TextInput.svelte";
    import Modal from "@components/overlay/Modal.svelte";
    import {FIELDNAME_HONEYPOT_APP_USERS} from "@modules/email-list/lists/waitlist-app-users/constants";
    import {modal} from "@stores/modal";
    import {mergeApiErrors, createFormFieldValidations, validateForm} from "@utils/form-utils";
    import {getApiSlug} from "@utils/router-utils";
    import type {FormChangeEventData, FormFocusEventData, SupportedLocale} from "../../../types";

    export let checkboxPrivacyLabel: ModalWaitlistAppUserProps["checkboxPrivacyLabel"];
    export let labelButtonSubmit: ModalWaitlistAppUserProps["labelButtonSubmit"];
    export let labelEmail: ModalWaitlistAppUserProps["labelEmail"];
    export let locale: ModalWaitlistAppUserProps["locale"];
    export let submitError: ModalWaitlistAppUserProps["submitError"];
    export let title: ModalWaitlistAppUserProps["title"];
    export let visible: ModalWaitlistAppUserProps["visible"];

    let isSubmitting = false;
    let validations = createFormFieldValidations(["name", "email", "emailConsent"]);
    $: formValidation = validateForm(validations, submitError);
    $: formReady = !isSubmitting && !formValidation.error;

    function handleFieldChange(evt: CustomEvent<FormChangeEventData>) {
        (validations as any)[evt.detail.name].error = undefined;
    }

    function handleFieldFocus(evt: CustomEvent<FormFocusEventData>) {
        (validations as any)[evt.detail.name].touched = true;
    }

    async function handleFormSubmit(evt: SubmitEvent) {
        evt.preventDefault();

        if (isSubmitting) {
            return;
        }
        isSubmitting = true;

        try {
            const formData = new FormData(evt.currentTarget as HTMLFormElement);
            const response = await fetch(getApiSlug("waitlistAppUsers"), {method: "POST", body: formData});

            isSubmitting = false;

            // form validation error
            if (response.status === 400) {
                const apiErrorData = await response.json();
                validations = mergeApiErrors(validations, apiErrorData) as any;
                return;
            }

            // other errors
            if (!response.ok) {
                throw new Error("Unhandled server error");
            }

            modal.open("waitlistSuccess");
        } catch (err) {
            modal.open("waitlistError");
        }
    }
</script>

<Modal {visible} hasCloseIcon on:close={modal.close}>
    <form on:submit={handleFormSubmit} class="theme-dark-colored">
        <h3 class="headline-2xl">{title}</h3>
        <TextInput
            hidden
            label={"Your name"}
            type="text"
            name={FIELDNAME_HONEYPOT_APP_USERS}
            validation={{touched: false, error: undefined}}
            on:change={handleFieldChange}
            on:focus={handleFieldFocus}
        />
        <TextInput
            label={labelEmail}
            type="email"
            name="email"
            validation={validations.email}
            on:change={handleFieldChange}
            on:focus={handleFieldFocus}
        />
        <HiddenInput name="locale" value={locale} />
        <Checkbox
            name="emailConsent"
            validation={validations.emailConsent}
            on:change={handleFieldChange}
            on:focus={handleFieldFocus}
        >
            {@html checkboxPrivacyLabel}
        </Checkbox>
        <div class="buttons">
            <Button appearance="solid" color="tertiary" disabled={!formReady} type="submit">
                {#if isSubmitting}
                    <ThreeQuarterSpinner />
                {:else}
                    {labelButtonSubmit}
                {/if}
            </Button>
        </div>
        <div class="form-error">
            <ErrorMessage validation={formValidation} />
        </div>
    </form>
</Modal>

<style>
    form {
        display: flex;
        flex-direction: column;
        gap: var(--size-4);
        padding-bottom: var(--size-2);
    }

    h3 {
        text-align: center;
    }

    .buttons {
        display: flex;
        justify-content: center;
    }

    .form-error {
        position: relative;
        text-align: center;
        height: var(--size-4);
    }
</style>
