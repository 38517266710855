import {parseLocale} from "@services/i18n";
import type {SupportedLocale} from "../types";
import {getDeploymentUrl} from "./env-utils";

type PageKey =
    | "about"
    | "emailListVerification"
    | "index"
    | "legalNotice"
    | "pricing"
    | "privacyNotice"
    | "recipeCreators"
    | "waitlist";

const pageSlugMap: Record<PageKey, Record<SupportedLocale, string>> = {
    about: {
        de: "/ueber-uns",
        en: "/about",
    },
    emailListVerification: {
        de: "/email-liste/verifizierung/:token",
        en: "/email-list/verification/:token",
    },
    index: {
        de: "",
        en: "",
    },
    legalNotice: {
        de: "/impressum",
        en: "/legal-notice",
    },
    pricing: {
        de: "/preise",
        en: "/pricing",
    },
    recipeCreators: {
        de: "/rezeptautoren",
        en: "/recipe-creators",
    },
    privacyNotice: {
        de: "/datenschutzerklaerung",
        en: "/privacy-notice",
    },
    waitlist: {
        de: "/warteliste",
        en: "/waitlist",
    },
};

type ApiSlugKey = "index" | "waitlistAppUsers" | "waitlistRecipeCreators";

const apiSlugMap: Record<ApiSlugKey, string> = {
    index: "",
    waitlistAppUsers: "/email-lists/waitlist-app-users",
    waitlistRecipeCreators: "/email-lists/waitlist-recipe-creators",
};

export const getDomain = () => {
    return getDeploymentUrl().hostname;
};

/**
 * Base URL is added only in SSR. No base URL is added for client-side code.
 */
export const withBaseUrl = (path: string) => {
    const url = getDeploymentUrl();
    url.pathname = path;
    return url.toString();
};

const parseSlugWithParams = (slugWithParams: string, params?: Record<string, string>): string => {
    if (!params) {
        return slugWithParams;
    }
    return slugWithParams
        .split("/")
        .map((segment) => (segment.startsWith(":") ? params[segment.slice(1)] : segment))
        .join("/");
};

export const getApiSlug = (apiSlugKey: ApiSlugKey, params?: Record<string, string>) => {
    const base = "/api";
    const slug = `${base}${apiSlugMap[apiSlugKey]}`;
    return parseSlugWithParams(slug, params);
};

export const getPageSlug = (locale: SupportedLocale) => (pageKey: PageKey, params?: Record<string, string>) => {
    const base = `/${locale}`;
    const slug = `${base}${pageSlugMap[pageKey][locale]}`;
    return parseSlugWithParams(slug, params);
};

export const stripTrailingSlash = (uri: string) => {
    return uri.replace(/\/$/, "");
};

export const getLocaleFromUrl = (urlOrString: URL | string): SupportedLocale => {
    const url = typeof urlOrString === "string" ? new URL(urlOrString) : urlOrString;
    return parseLocale(url.pathname.split("/")[1]);
};

export const getAlternateSlugs = (pageKey: PageKey, params?: Record<string, string>): Record<SupportedLocale, string> => {
    return Object.keys(pageSlugMap[pageKey]).reduce(
        (acc, locale) => ({
            ...acc,
            [locale]: getPageSlug(locale as SupportedLocale)(pageKey, params),
        }),
        {} as any,
    );
};
