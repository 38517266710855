<script context="module" lang="ts">
    import {createEventDispatcher} from "svelte";
    import type {FormChangeEventData, FormFocusEventData, FormValidationObject} from "../../types";

    export interface TextInputProps {
        className?: string;
        hidden: boolean;
        id?: string;
        label?: string;
        name: string;
        placeholder?: string;
        required?: boolean;
        theme?: "light" | "dark";
        type?: "text" | "email" | "url";
        validation: FormValidationObject;
        value?: string;
    }
</script>

<script lang="ts">
    import {generateRandomId} from "@utils/randomization-utils";
    import ErrorMessage from "./ErrorMessage.svelte";

    let className = "";
    export {className as class};
    export let hidden: TextInputProps["hidden"] = false;
    export let id: TextInputProps["id"] = generateRandomId({prefix: "input-"});
    export let label: TextInputProps["label"] = undefined;
    export let name: TextInputProps["name"];
    export let placeholder: TextInputProps["placeholder"] = undefined;
    export let required: TextInputProps["required"] = undefined;
    export let theme: TextInputProps["theme"] = "dark";
    export let type: TextInputProps["type"] = "text";
    export let validation: TextInputProps["validation"];
    export let value: TextInputProps["value"] = "";

    if (!name) {
        throw new Error("Missing name prop in TextInput component.");
    }
    if (!validation) {
        throw new Error(`Missing validation prop in TextInput component with name "${name}".`);
    }

    const dispatch = createEventDispatcher();

    function handleChange(evt: any) {
        const value = evt.target.value as string;
        const detail: FormChangeEventData = {name, value};
        dispatch("change", detail);
    }

    function handleFocus(evt: any) {
        const value = evt.target.value as string;
        const detail: FormFocusEventData = {name, value};
        dispatch("focus", detail);
    }
</script>

<div class={`root ${theme}`} class:hidden>
    {#if label}<label for={id}>{label}</label>{/if}
    <div class="input-box focusable" class:error={validation.error}>
        {#if $$slots.prefix}
            <span class="prefix">
                <slot name="prefix" />
            </span>
        {/if}
        <input
            class={className}
            {id}
            {type}
            {name}
            {value}
            {placeholder}
            {required}
            on:change={handleChange}
            on:focus={handleFocus}
        />
        {#if $$slots.suffix}
            <span class="suffix">
                <slot name="suffix" />
            </span>
        {/if}
    </div>

    <ErrorMessage {validation} />
</div>

<style>
    .light {
        --color-bg: var(--color-shade-900);
        --color-border: var(--color-shade-600);
        --color-label: var(--color-shade-300);
        --color-prefix-suffix-bg: var(--color-shade-700);
        --color-prefix-suffix-text: var(--color-shade-200);
        --color-text: var(--color-shade-50);
    }
    .dark {
        --color-bg: var(--color-shade-50);
        --color-border: var(--color-shade-200);
        --color-label: var(--color-shade-600);
        --color-prefix-suffix-bg: var(--color-shade-100);
        --color-prefix-suffix-text: var(--color-shade-700);
        --color-text: var(--color-shade-900);
    }

    .root {
        display: flex;
        flex-direction: column;
        flex-grow: 1;
        gap: 0;
    }

    .hidden {
        display: none;
    }

    label {
        font-size: var(--font-size-sm);
        color: var(--color-label);
    }

    .input-box {
        align-items: center;
        background-color: var(--color-bg);
        border: var(--size-px) solid var(--color-border);
        border-radius: var(--radius-xs);
        box-shadow: var(--shadow-sm);
        box-sizing: border-box;
        display: flex;
        overflow: hidden;
        padding: 0;
        transition: box-shadow 200ms ease-out;
        width: 100%;
    }
    .input-box.error {
        border-color: var(--color-quaternary-500);
    }
    .input-box:focus-within {
        box-shadow: inset var(--shadow-sm);
    }

    input {
        background-color: transparent;
        border: none;
        box-sizing: border-box;
        color: var(--color-text);
        flex-grow: 1;
        font-size: var(--font-size-md);
        line-height: var(--leading-tight);
        padding: var(--size-2-5);
        width: 100%;
    }

    .prefix,
    .suffix {
        color: var(--color-prefix-suffix-text);
        background-color: var(--color-prefix-suffix-bg);
        padding: var(--size-2-5);
    }
</style>
