<script context="module" lang="ts">
    import {createEventDispatcher} from "svelte";
    import type {FormValidationObject} from "../../types";

    export interface CheckboxProps {
        checked?: boolean;
        class?: string;
        id?: string;
        name?: string;
        required?: boolean;
        validation: FormValidationObject;
    }
</script>

<script lang="ts">
    import {generateRandomId} from "@utils/randomization-utils";
    import ErrorMessage from "./ErrorMessage.svelte";

    let className: CheckboxProps["class"] = "";
    export {className as class};
    export let checked: CheckboxProps["checked"] = false;
    export let id: CheckboxProps["id"] = generateRandomId({prefix: "input-"});
    export let name: CheckboxProps["name"] = undefined;
    export let required: CheckboxProps["required"] = undefined;
    export let validation: CheckboxProps["validation"];

    const dispatch = createEventDispatcher();

    function handleChange(evt: any) {
        const value = evt.target.value as string;
        dispatch("change", {name, value});
    }

    function handleFocus(evt: any) {
        const value = evt.target.value as string;
        dispatch("focus", {name, value});
    }
</script>

<div class="checkbox">
    <input {checked} class={className} {id} {name} {required} type="checkbox" on:change={handleChange} on:focus={handleFocus} />
    <label for={id}>
        <slot />
    </label>
    <ErrorMessage {validation} />
</div>

<style>
    .checkbox {
        position: relative;
        padding-bottom: var(--size-1);
    }
</style>
