<script lang="ts">
    import {fade} from "svelte/transition";
    import type {FormValidationObject} from "../../types";
    import {combineClasses} from "@utils/component-utils";

    let className: string = "";
    export {className as class};
    export let validation: FormValidationObject;

    if (!validation) {
        throw new Error("Missing validation prop in ErrorMessage component.");
    }

    $: errorMsg = validation.error?.message || "";
</script>

<div class={combineClasses(["root", className])}>
    {#if validation?.error}
        <p class="msg" in:fade out:fade>
            {errorMsg}
        </p>
    {/if}
</div>

<style>
    .root {
        --font-size: var(--font-size-xs);
        line-height: 1;
        min-height: var(--font-size);
        padding-top: var(--size-px);
    }
    .msg {
        color: var(--color-quaternary-500);
        display: block;
        font-size: var(--font-size-xs);
        margin: 0;
        padding: 0;
        width: 100%;
    }
</style>
