<script lang="ts" context="module">
    export interface ModalWaitlistRecipeCreatorProps {
        checkboxPrivacyLabel: string;
        hint: string;
        labelButtonSubmit: string;
        labelEmail: string;
        labelInstagram: string;
        labelYouTube: string;
        labelTikTok: string;
        labelFacebook: string;
        labelTwitter: string;
        labelTwitch: string;
        labelPinterest: string;
        labelSnapchat: string;
        labelWebsite: string;
        locale: SupportedLocale;
        submitError: string;
        title: string;
        titleContact: string;
        titleSocialMedia: string;
        visible: boolean;
    }
</script>

<script lang="ts">
    import Button from "@components/controls/Button.svelte";
    import Checkbox from "@components/controls/Checkbox.svelte";
    import TextInput from "@components/controls/TextInput.svelte";
    import Modal from "@components/overlay/Modal.svelte";
    import HiddenInput from "@components/controls/HiddenInput.svelte";
    import ErrorMessage from "@components/controls/ErrorMessage.svelte";
    import ThreeQuarterSpinner from "@components/animations/ThreeQuarterSpinner.svelte";
    import {FIELDNAME_HONEYPOT_RECIPE_CREATORS} from "@modules/email-list/lists/waitlist-recipe-creators/constants";
    import {modal} from "@stores/modal";
    import {createFormFieldValidations, mergeApiErrors, validateForm} from "@utils/form-utils";
    import {getApiSlug} from "@utils/router-utils";
    import type {FormChangeEventData, FormFocusEventData, SupportedLocale} from "../../../types";

    export let checkboxPrivacyLabel: ModalWaitlistRecipeCreatorProps["checkboxPrivacyLabel"];
    export let hint: ModalWaitlistRecipeCreatorProps["hint"];
    export let labelButtonSubmit: ModalWaitlistRecipeCreatorProps["labelButtonSubmit"];
    export let labelEmail: ModalWaitlistRecipeCreatorProps["labelEmail"];
    export let labelInstagram: ModalWaitlistRecipeCreatorProps["labelInstagram"];
    export let labelYouTube: ModalWaitlistRecipeCreatorProps["labelYouTube"];
    export let labelTikTok: ModalWaitlistRecipeCreatorProps["labelTikTok"];
    export let labelFacebook: ModalWaitlistRecipeCreatorProps["labelFacebook"];
    export let labelTwitter: ModalWaitlistRecipeCreatorProps["labelTwitter"];
    export let labelTwitch: ModalWaitlistRecipeCreatorProps["labelTwitch"];
    export let labelPinterest: ModalWaitlistRecipeCreatorProps["labelPinterest"];
    export let labelSnapchat: ModalWaitlistRecipeCreatorProps["labelSnapchat"];
    export let labelWebsite: ModalWaitlistRecipeCreatorProps["labelWebsite"];
    export let locale: ModalWaitlistRecipeCreatorProps["locale"];
    export let submitError: ModalWaitlistRecipeCreatorProps["submitError"];
    export let title: ModalWaitlistRecipeCreatorProps["title"];
    export let titleContact: ModalWaitlistRecipeCreatorProps["titleContact"];
    export let titleSocialMedia: ModalWaitlistRecipeCreatorProps["titleSocialMedia"];
    export let visible: ModalWaitlistRecipeCreatorProps["visible"];

    let isSubmitting = false;
    let validations = createFormFieldValidations([
        "name",
        "email",
        "emailConsent",
        "platform",
        "platformInstagram",
        "platformYoutube",
        "platformFacebook",
        "platformTiktok",
        "platformTwitter",
        "platformTwitch",
        "platformPinterest",
        "platformSnapchat",
        "platformWebsite",
    ]);

    $: formValidation = validateForm(validations, submitError);
    $: formReady = !isSubmitting && !formValidation.error;

    function handleFieldChange(evt: CustomEvent<FormChangeEventData>) {
        (validations as any)[evt.detail.name].error = undefined;

        // reset platform error when any platform field changes
        if (evt.detail.name.startsWith("platform")) {
            validations.platform.error = undefined;
        }
    }

    function handleFieldFocus(evt: CustomEvent<FormFocusEventData>) {
        (validations as any)[evt.detail.name].touched = true;
    }

    async function handleFormSubmit(evt: SubmitEvent) {
        evt.preventDefault();

        if (isSubmitting) {
            return;
        }
        isSubmitting = true;

        try {
            const formData = new FormData(evt.currentTarget as HTMLFormElement);
            const response = await fetch(getApiSlug("waitlistRecipeCreators"), {method: "POST", body: formData});

            isSubmitting = false;

            // form validation error
            if (response.status === 400) {
                const apiErrorData = await response.json();
                validations = mergeApiErrors(validations, apiErrorData) as any;
                return;
            }

            // other errors
            if (!response.ok) {
                throw new Error("Unhandled server error");
            }

            modal.open("waitlistSuccess");
        } catch (err) {
            modal.open("waitlistError");
        }
    }
</script>

<Modal {visible} hasCloseIcon on:close={modal.close}>
    <form on:submit={handleFormSubmit} class="theme-dark-colored">
        <h3 class="headline-2xl">{title}</h3>
        <fieldset>
            <legend>{titleContact}</legend>
            <TextInput
                hidden
                label={"Your name"}
                type="text"
                name={FIELDNAME_HONEYPOT_RECIPE_CREATORS}
                validation={{touched: false, error: undefined}}
                on:change={handleFieldChange}
                on:focus={handleFieldFocus}
            />
            <TextInput
                label={labelEmail}
                type="email"
                name="email"
                validation={validations.email}
                on:change={handleFieldChange}
                on:focus={handleFieldFocus}
            />
        </fieldset>
        <fieldset>
            <legend>{titleSocialMedia}</legend>
            <ErrorMessage class="platform-error" validation={validations.platform} />
            <TextInput
                label={labelInstagram}
                type="text"
                name="platformInstagram"
                validation={validations.platformInstagram}
                on:change={handleFieldChange}
                on:focus={handleFieldFocus}
            >
                <span slot="prefix">@</span>
            </TextInput>
            <TextInput
                label={labelYouTube}
                type="text"
                name="platformYoutube"
                validation={validations.platformYoutube}
                on:change={handleFieldChange}
                on:focus={handleFieldFocus}
            >
                <span slot="prefix">@</span>
            </TextInput>
            <TextInput
                label={labelTikTok}
                type="text"
                name="platformTiktok"
                validation={validations.platformTiktok}
                on:change={handleFieldChange}
                on:focus={handleFieldFocus}
            >
                <span slot="prefix">@</span>
            </TextInput>
            <TextInput
                label={labelFacebook}
                type="text"
                name="platformFacebook"
                validation={validations.platformFacebook}
                on:change={handleFieldChange}
                on:focus={handleFieldFocus}
            >
                <span slot="prefix">@</span>
            </TextInput>
            <TextInput
                label={labelTwitter}
                type="text"
                name="platformTwitter"
                validation={validations.platformTwitter}
                on:change={handleFieldChange}
                on:focus={handleFieldFocus}
            >
                <span slot="prefix">@</span>
            </TextInput>
            <TextInput
                label={labelTwitch}
                type="text"
                name="platformTwitch"
                validation={validations.platformTwitch}
                on:change={handleFieldChange}
                on:focus={handleFieldFocus}
            >
                <span slot="prefix">@</span>
            </TextInput>
            <TextInput
                label={labelPinterest}
                type="text"
                name="platformPinterest"
                validation={validations.platformPinterest}
                on:change={handleFieldChange}
                on:focus={handleFieldFocus}
            >
                <span slot="prefix">@</span>
            </TextInput>
            <TextInput
                label={labelSnapchat}
                type="text"
                name="platformSnapchat"
                validation={validations.platformSnapchat}
                on:change={handleFieldChange}
                on:focus={handleFieldFocus}
            >
                <span slot="prefix">@</span>
            </TextInput>
            <TextInput
                label={labelWebsite}
                type="text"
                name="platformWebsite"
                validation={validations.platformWebsite}
                on:change={handleFieldChange}
                on:focus={handleFieldFocus}
            />
        </fieldset>
        <fieldset>
            <Checkbox
                name="emailConsent"
                validation={validations.emailConsent}
                on:change={handleFieldChange}
                on:focus={handleFieldFocus}
            >
                {@html checkboxPrivacyLabel}
            </Checkbox>
        </fieldset>
        <fieldset>
            <HiddenInput name="locale" value={locale} />
            <div class="buttons">
                <Button appearance="solid" color="tertiary" disabled={!formReady} type="submit">
                    {#if isSubmitting}
                        <ThreeQuarterSpinner />
                    {:else}
                        {labelButtonSubmit}
                    {/if}
                </Button>
            </div>
            <div class="form-error">
                <ErrorMessage validation={formValidation} />
            </div>
            <p class="hint">{@html hint}</p>
        </fieldset>
    </form>
</Modal>

<style>
    form {
        display: flex;
        flex-direction: column;
        gap: var(--size-10);
        padding-bottom: var(--size-2);
    }

    h3 {
        text-align: center;
    }

    fieldset {
        border: none;
        display: flex;
        flex-direction: column;
        gap: var(--size-4);
        margin: 0;
        padding: 0;
    }

    fieldset > legend {
        color: var(--color-shade-900);
        font-size: var(--font-size-md);
        font-weight: var(--font-weight-bold);
        margin-bottom: var(--size-4);
        padding: 0;
    }
    fieldset > legend:has(+ .platform-error) {
        margin-bottom: 0;
    }

    fieldset:last-child {
        display: flex;
        flex-direction: column;
        justify-content: center;
    }

    fieldset:last-child > .buttons {
        display: flex;
        justify-content: center;
    }

    .hint {
        margin-top: 0;
    }

    .form-error {
        position: relative;
        text-align: center;
        height: var(--size-4);
    }
</style>
