<script lang="ts">
    import {fade} from "svelte/transition";
    import type {ButtonProps} from "@components/controls/Button.svelte";
    import Backdrop from "./Backdrop.svelte";
    import ModalWaitlistAppUser from "./modals/ModalWaitlistAppUser.svelte";
    import type {ModalWaitlistAppUserProps} from "./modals/ModalWaitlistAppUser.svelte";
    import ModalWaitlistRecipeCreator from "./modals/ModalWaitlistRecipeCreator.svelte";
    import type {ModalWaitlistRecipeCreatorProps} from "./modals/ModalWaitlistRecipeCreator.svelte";
    import ModalWaitlistSuccess from "./modals/ModalWaitlistSuccess.svelte";
    import type {ModalWaitlistSuccessProps} from "./modals/ModalWaitlistSuccess.svelte";
    import ModalWaitlistError from "./modals/ModalWaitlistError.svelte";
    import type {ModalWaitlistErrorProps} from "./modals/ModalWaitlistError.svelte";
    import {modal} from "@stores/modal";

    let className: ButtonProps["class"] = "";
    export {className as class};

    export let modalWaitlistAppUser: Omit<ModalWaitlistAppUserProps, "visible">;
    export let modalWaitlistRecipeCreator: Omit<ModalWaitlistRecipeCreatorProps, "visible">;
    export let modalWaitlistSuccess: Omit<ModalWaitlistSuccessProps, "visible">;
    export let modalWaitlistError: Omit<ModalWaitlistErrorProps, "visible">;

    $: overlayVisible = Boolean($modal);
</script>

{#if overlayVisible}
    <div class={className} class:root={true} transition:fade>
        <Backdrop visible={Boolean($modal)} />
        <ModalWaitlistAppUser {...modalWaitlistAppUser} visible={$modal === "waitlistAppUser"} />
        <ModalWaitlistRecipeCreator {...modalWaitlistRecipeCreator} visible={$modal === "waitlistRecipeCreator"} />
        <ModalWaitlistSuccess {...modalWaitlistSuccess} visible={$modal === "waitlistSuccess"} />
        <ModalWaitlistError {...modalWaitlistError} visible={$modal === "waitlistError"} />
    </div>
{/if}

<style>
    .root {
        align-items: center;
        box-sizing: border-box;
        bottom: 0;
        display: flex;
        justify-content: center;
        left: 0;
        max-height: 100vh;
        padding-bottom: var(--size-10);
        padding-top: var(--size-10);
        position: fixed;
        right: 0;
        top: 0;
        z-index: var(--z-index-overlay);
    }
</style>
