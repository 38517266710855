<script lang="ts" context="module">
    export interface ModalWaitlistErrorProps {
        labelButtonClose: string;
        text: string;
        title: string;
        visible: boolean;
    }
</script>

<script lang="ts">
    import Button from "@components/controls/Button.svelte";
    import IconFaXmarkSolid from "@components/icons/IconFaXmarkSolid.svelte";
    import Modal from "@components/overlay/Modal.svelte";
    import {modal} from "@stores/modal";

    export let title: ModalWaitlistErrorProps["title"];
    export let text: ModalWaitlistErrorProps["text"];
    export let labelButtonClose: ModalWaitlistErrorProps["labelButtonClose"];
    export let visible: ModalWaitlistErrorProps["visible"];
</script>

<Modal {visible}>
    <div class="body theme-dark-colored">
        <h3 class="headline-2xl">{title}</h3>
        <div class="icon-check">
            <IconFaXmarkSolid />
        </div>
        <div>
            <p>{@html text}</p>
        </div>
        <div class="buttons">
            <Button appearance="ghost" color="dark" on:click={modal.close}>{labelButtonClose}</Button>
        </div>
    </div>
</Modal>

<style>
    .body {
        display: flex;
        flex-direction: column;
        text-align: center;
        gap: var(--size-4);
    }

    h3 {
        text-align: center;
    }

    .icon-check {
        color: var(--color-quaternary-500);
        font-size: var(--font-size-3xl);
    }

    .buttons {
        display: flex;
        justify-content: center;
    }
</style>
