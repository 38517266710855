import fromPairs from "lodash/fromPairs";
import type {FormValidationObject, FormValidationIssue, FormValidationResponseData, FormValidationMap} from "../types";

export function createFormFieldValidations<F extends string>(fieldNames: F[]) {
    return fieldNames.reduce(
        (acc, fieldName) => ({
            ...acc,
            [fieldName]: {
                touched: false,
                error: undefined,
            },
        }),
        {} as Record<F, FormValidationObject>,
    );
}

function checkFormHasErrorField(fields: FormValidationMap) {
    return Boolean(Object.values(fields).find((field) => Boolean(field.error)));
}

function checkFormTouched(fields: FormValidationMap) {
    return Boolean(Object.values(fields).find((field) => field.touched));
}

/**
 * Checks the entire form if it's touched and if it has any errors.
 */
export function validateForm(validations: FormValidationMap, message: string): FormValidationObject {
    const formHasError = checkFormHasErrorField(validations);
    const error = formHasError ? {code: "invalid_fields", message} : undefined;
    const touched = checkFormTouched(validations);
    return {error, touched};
}

const createErrorFromIssue = (issue: FormValidationIssue) => ({
    message: issue.message,
    code: issue.code,
});

const findIssueFromList = (fieldName: string, apiErrorData: FormValidationResponseData) => {
    return apiErrorData.issues.find((issue) => issue.path.join(".") === fieldName);
};

export function mergeApiErrors(fields: FormValidationMap, apiErrorData: FormValidationResponseData) {
    return Object.entries(fields).reduce((result, [fieldName, fieldValue]) => {
        const issue = findIssueFromList(fieldName, apiErrorData);
        return {
            ...result,
            [fieldName]: {
                ...fieldValue,
                error: issue ? createErrorFromIssue(issue) : undefined,
            },
        };
    }, {});
}

export const formDataToObject = (formData: FormData) => {
    return fromPairs(Array.from(formData.entries()));
};

type FormDataParseResult = Promise<{success: true; data: FormData} | {success: false; errors: {issues: any[]}}>;

export const parseFormData = async (request: Request): FormDataParseResult => {
    try {
        const formData = await request.formData();
        return {success: true, data: formData};
    } catch (error) {
        const errors = {issues: [{path: "form", message: "Failed to parse form data."}], message: "Invalid form data"};
        return {success: false, errors};
    }
};
