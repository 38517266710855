<script lang="ts">
    import {createEventDispatcher} from "svelte";
    import {fade} from "svelte/transition";
    import Button from "@components/controls/Button.svelte";
    import IconFaXmarkSolid from "@components/icons/IconFaXmarkSolid.svelte";

    export let visible = false;
    export let hasCloseIcon: boolean | undefined = undefined;

    const dispatch = createEventDispatcher();

    function handleClickClose(evt: MouseEvent) {
        evt.preventDefault();
        dispatch("close", evt.detail);
    }
</script>

{#if visible}
    <div class="wrapper">
        <section class="modal" transition:fade>
            {#if hasCloseIcon}
                <div class="top-bar">
                    <Button appearance="link" size="xl" color="dark" type="button" on:click={handleClickClose}>
                        <IconFaXmarkSolid />
                    </Button>
                </div>
            {/if}
            {#if $$slots.default}
                <div class="overflow-y-auto">
                    <slot />
                </div>
            {/if}
            {#if $$slots.footer}
                <footer>
                    <slot name="footer" />
                </footer>
            {/if}
        </section>
    </div>
{/if}

<style>
    /* use wrapper to add a gap between modal and screen */
    .wrapper {
        box-sizing: border-box;
        display: flex;
        padding: var(--size-6);
        position: absolute;
        max-height: 100%;
        max-width: var(--size-152);
    }

    .modal {
        background: var(--color-bg-50);
        border-radius: var(--radius-md);
        box-sizing: border-box;
        display: flex;
        flex-direction: column;
        gap: var(--size-4);
        padding: 0;
        padding-bottom: var(--size-8);
        padding-top: var(--size-8);
    }

    /* use padding in section children to create a gap between content and scrollbars */
    .modal > * {
        padding-left: var(--size-8);
        padding-right: var(--size-8);
    }

    .top-bar {
        display: flex;
        justify-content: right;
        margin-top: calc(-1 * var(--size-2-5));
    }
</style>
